import React, {useEffect, useState} from 'react';
import {BrowserRouter, Route, Switch, Redirect, useHistory, useLocation} from "react-router-dom";

import {Login} from "./views/login";
import {Layout} from "./components/Layout";
import {Dashboard} from "./views/dashboard";
import {Templates} from "./views/templates";
import {Chats} from "./views/chats";

function App() {
    return (
            <BrowserRouter>
                <Switch>
                    <Route path="/login" component={Login}/>
                    <Layout>
                        <Route path="/" exact component={Dashboard}/>
                        <Route path="/templates" component={Templates}/>
                        <Route path="/chats/:chatId?" component={Chats}/>
                        <Redirect to="/" path="*"/>
                    </Layout>
                </Switch>
            </BrowserRouter>
    );
}

export default App;
