export type TemplateEntity = {
    id: number,
    name: string,
    russian_text: string,
    english_text: string,
    actions: ActionEntity[]
}

export type ActionEntity = {
    id: number,
    russian_text: string,
    english_text: string,
    url: string,
    callback_data: string,
}

export type UserEntity = {
    id: number,
    firstName: string,
    lastName: string,
    username: string,
    language: Language,
    active: boolean,
}

export type ChatEntity = {
    chat_id: string,
    text: string,
    date: number,
} & UserEntity

export type AudioEntity = {
    id: string,
    downloaded: boolean,
    url: string
}

export type MessageEntity = {
    id: number,
    message_type: MessageType,
    text: string,
    audio: AudioEntity,
    location: string,
    date: number,
    author: UserEntity
}

export enum Language {
    Russian = "ru",
    English = "en"
}

export enum MessageType {
    MESSAGE = "message",
    AUDIO = "audio",
    ACTION = "action",
    LOCATION = "location",
    CHAT_MEMBER_JOIN = "chat_member_join",
    CHAT_MEMBER_LEAVE = "chat_member_leave",
}
