import React from "react";
import {MessageEntity, MessageType} from "../../types/entities";
import "./message.scss";

type MessageProps = {} & MessageEntity

export const Message = ({text, message_type, location, author, audio}: MessageProps) => {

    const itsMe = () => {
        return author.id == 1
    }

    const getChatUserName = () => {
        const {firstName, username, lastName} = author;
        return [firstName, username, lastName]
            .filter(Boolean)
            .join(" ");
    }

    const getLocation = () => {
        const loc = JSON.parse(location);
        return `${loc["latitude"]},%20${loc["longitude"]}`
    }

    const renderMessageContent = () => {
        switch (message_type) {
            case MessageType.CHAT_MEMBER_JOIN:
                return (
                    <>
                        <div className="ls-message-chat-user">{getChatUserName()}</div>
                        <div className="ls-message-chat-user-event">joined channel</div>
                    </>
                );
            case MessageType.CHAT_MEMBER_LEAVE:
                return (
                    <>
                        <div className="ls-message-chat-user">{getChatUserName()}</div>
                        <div className="ls-message-chat-user-event">left channel</div>
                    </>
                )
            case MessageType.MESSAGE:
                return (
                    <div className="ls-message-text">{text}</div>
                );
            case MessageType.AUDIO:
                return (
                    <audio className="ls-message-audio"
                           controls={true}
                           autoPlay={false}
                           src={audio.url} />
                );
            case MessageType.LOCATION:
                return (
                    <a href={`https://maps.google.com/maps?q=${getLocation()}`} target="_blank">Location</a>
                )
            case MessageType.ACTION:
                return (
                    <div className="ls-message-text">User selected action</div>
                )
        }
    }

    if ([MessageType.CHAT_MEMBER_JOIN, MessageType.CHAT_MEMBER_LEAVE].includes(message_type)) {
        return (
            <div className="ls-message-event">
                {renderMessageContent()}
            </div>
        )
    }

    return (
        <div className={"ls-message" + (itsMe() ? " ls-message-mine" : "")}>
            {renderMessageContent()}
        </div>
    )
}