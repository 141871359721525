import * as React from "react";
import {Layout as AntdLayout, Menu} from "antd"
import {Link, RouteComponentProps, useLocation, useHistory} from "react-router-dom";
import {DashboardOutlined, FormOutlined, WechatOutlined} from '@ant-design/icons';
import {FaHome, FaTelegramPlane, FaStickyNote} from "react-icons/fa";
import "./Layout.scss";
import {useEffect, useState} from "react";

const {Sider, Content} = AntdLayout;

type LayoutProps = {
    children: any
}

export const Layout = ({children}: LayoutProps) => {
    const location = useLocation();
    const history = useHistory();

    const [selectedKey, setSelectedKey] = useState(['dashboard']);

    useEffect(() => {
        let currentPath = location.pathname.substring(1).split("/")[0];
        if (!currentPath.length) {
            currentPath = 'dashboard';
        }

        setSelectedKey([currentPath]);
    }, [location]);

    return (
        <AntdLayout className="ls-layout">
            <Sider theme="light"
                   className="ls-sider"
                   width={250}>
                <div className="ls-logo">LOCATION stories</div>
                <Menu mode="inline"
                      selectedKeys={selectedKey}
                      className="ls-menu">
                    <Menu.Item key={'dashboard'}
                               icon={<FaHome />}>
                        <Link to={'/'}>Dashboard</Link>
                    </Menu.Item>

                    <Menu.Item key={'templates'}
                               icon={<FaStickyNote />}>

                        <Link to={'/templates'}>Templates</Link>
                    </Menu.Item>

                    <Menu.Item key={'chats'}
                               icon={<FaTelegramPlane />}>
                        <Link to={'/chats'}>Chats</Link>
                    </Menu.Item>
                </Menu>
            </Sider>

            <Content className="ls-content">
                <div className="ls-content-container">
                    {children}
                </div>
            </Content>
        </AntdLayout>
    )
}