import {Card, Form, Input} from "antd";
import React from "react";
import {ActionEntity} from "../../types/entities";
import "./action.scss"

type ActionCardProps = {
    onChange: (id: number, action: Partial<ActionEntity>) => void;
} & ActionEntity

export const ActionCard = ({onChange, id, russian_text, english_text, callback_data, url}: ActionCardProps) => {

    return (
        <Card className="ls-action-card">
            <Form labelCol={{span: 4}} wrapperCol={{span: 20}} labelAlign="left">
                <Form.Item label="Russian Text">
                    <Input value={russian_text}
                           onChange={(e) => onChange(id, {russian_text: e.target.value})}/>
                </Form.Item>

                <Form.Item label="English Text">
                    <Input value={english_text}
                           onChange={(e) => onChange(id, {english_text: e.target.value})}/>
                </Form.Item>

                {callback_data && <Form.Item label="Callback Data">
                    <Input disabled value={callback_data}/>
                </Form.Item>}


                {url && <Form.Item label="Url">
                    <Input value={url} onChange={(e) => onChange(id, {url: e.target.value})}/>
                </Form.Item>}
            </Form>
        </Card>
    )
}