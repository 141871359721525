import React, {useState, useEffect} from "react";
import {Typography, Button, notification, Input, Row, Col, Space, Spin, Divider} from "antd";
import "./template.scss";
import {useApi} from "../../api";
import {ActionEntity, TemplateEntity} from "../../types/entities";
import {ActionCard} from "../action";
import {withDelay} from "../../common/utils/withDelay";
import io, {Socket} from "socket.io-client";

type TemplateProps = {
    id: number,
    name: string,
}

export const Template = ({id, name}: TemplateProps) => {
    const templatesApi = useApi('templates');
    const [websocket, setWebsocket] = useState<typeof Socket>();

    const [ruText, setRuText] = useState<string>('');
    const [enText, setEnText] = useState<string>('');
    const [actions, setActions] = useState<ActionEntity[]>([])

    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if (websocket && websocket.connected) {
            websocket.close();
        }

        const ws = io.connect('ws://localhost:8443/templates', {
            transports: ['websocket'],
        })

        setWebsocket(ws)

        return () => {
           ws.close()
        }
    }, [])

    useEffect(() => {
        loadTemplate(id);
    }, [id])

    useEffect(() => {
        const templateUpdatedCallback = (data: any) => {
            if (data['client_id'] !== websocket?.id) {
                loadTemplate(data['template_id']);
            }
        }

        websocket?.on('template_updated', templateUpdatedCallback);

        return () => {
            websocket?.off('template_updated', templateUpdatedCallback)
        }
    }, [websocket])

    const loadTemplate = (templateId: number) => {
        setLoading(true);
        templatesApi.get<TemplateEntity>(templateId.toString())
            .then(result => {
                const data = result.data;
                setRuText(data.russian_text);
                setEnText(data.english_text);
                setActions(data.actions);
            })
            .finally(() => withDelay(() => setLoading(false), 500));
    }

    const onSave = () => {
        setSaving(true);
        templatesApi.post(id.toString(), {
            russian_text: ruText,
            english_text: enText,
            actions: actions
        })
            .then(() => {
                notification.open({
                    message: 'Update successful',
                    description: `Template "${name}" successfully updated`,
                    style: {
                        width: 600
                    }
                })
            })
            .catch(err => {
                notification.open({
                    message: 'Update failed',
                    description: `Template "${name}" updating failed`,
                    style: {
                        width: 600
                    }
                })
                console.error(err);
            })
            .finally(() => {
                websocket?.emit('template_updated', id);
                withDelay(() => setSaving(false), 500)
            });
    }

    const onActionChange = (id: number, action: Partial<ActionEntity>) => {
        const newActions = actions.map(a => {
            if (a.id === id) {
                return {...a, ...action}
            }
            return a;
        });
        setActions(newActions);
    }

    const textAreaAutosize = {minRows: 2};

    const haveActions = () => Boolean(actions && actions.length);

    if (loading) {
        return (
            <Spin/>
        )
    }

    return (
        <Spin spinning={saving} className="ls-template">
            <Row gutter={16}>
                <Col span={12} className="ls-editor-wrapper">
                    <Typography.Title level={4} children={"Russian Text"}/>
                    <Input.TextArea autoSize={textAreaAutosize}
                                    value={ruText}
                                    onChange={(e) => setRuText(e.target.value)}/>
                </Col>
                <Col span={12} className="ls-editor-wrapper">
                    <Typography.Title level={4} children={"English Text"}/>
                    <Input.TextArea autoSize={textAreaAutosize}
                                    value={enText}
                                    onChange={(e) => setEnText(e.target.value)}/>
                </Col>
            </Row>

            {
                haveActions() && <>
                    <Divider/>
                    <Typography.Title level={4} children={"Actions"}/>
                    <Row className="ls-action-cards">
                        {
                            actions.map(a => {
                                return (
                                    <Col key={`TEMPLATE_${id}_ACTION_CARD_${a.id}`} span={24 / actions.length}>
                                        <ActionCard {...a} onChange={onActionChange}/>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </>
            }

            <Divider/>
            <Button block type="primary" loading={loading} onClick={onSave}>Save</Button>
        </Spin>
    );
}