import React, {useEffect, useState, useRef} from "react"
import {ChatEntity, MessageEntity} from "../../types/entities";
import {useApi, useWebsocket} from "../../api";
import {withDelay} from "../../common/utils/withDelay";
import {Message} from "../message";
import {Button, Col, Input, Row} from "antd";
import io, {Socket} from "socket.io-client";
import {AiOutlineSend} from "react-icons/ai";
import "./chat.scss"

type ChatProps = {
} & ChatEntity

export const ChatV2 = ({...chat}: ChatProps) => {
    const messagesAPI = useApi('messages');
    const messagesWS = useWebsocket('messages');

    const [messages, setMessages] = useState<MessageEntity[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const [messageText, setMessageText] = useState<string>();

    const lastMessageRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const newMessageCallback = (data: any) => {
            if (data['chat_id'] == chat.chat_id) {
                loadMessages(data['chat_id']);
            }
        }

        messagesWS?.on('new_messages', newMessageCallback);

        return () => {
            messagesWS?.off('new_messages', newMessageCallback)
        }
    }, [])

    useEffect(() => {
        loadMessages(chat.chat_id)
    }, [chat.chat_id]);

    useEffect(() => {
        if (lastMessageRef && lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({behavior: "smooth"})
        }

    }, [messages])

    const loadMessages = (chatId: string) => {
        setLoading(true);
        messagesAPI.get<MessageEntity[]>(`chat/${chatId}`)
            .then(result => {
                setMessages(result.data);
            })
            .finally(() => withDelay(() => setLoading(false), 500))
    }

    const sendMessage = () => {
        if (messageText && messageText.length) {
            messagesAPI.post(`send/${chat.chat_id}`, {message: messageText})
                .then(() => {
                    setMessageText('');
                });
        }
    }

    return (
        <div className="ls-chat">
            <div className="ls-chat-messages">
                {
                    messages.map((m, i) => {
                        return <Message key={m.id} {...m} />
                    })
                }
                <div ref={lastMessageRef}/>
            </div>
            <div className="ls-chat-control">
                <Row>
                    <Col span={22}>
                        <Input.TextArea placeholder="Write a message..."
                                        bordered={false}
                                        autoSize={{minRows: 2, maxRows: 6}} value={messageText}
                                        onChange={(e) => setMessageText(e.target.value)}/>
                    </Col>
                    <Col span={2}>
                        <Button type="text" className="ls-chat-send-button" block icon={<AiOutlineSend/>}
                                onClick={sendMessage}/>
                    </Col>
                </Row>
            </div>

        </div>
    )
}