import React, {useEffect, useState} from "react";
import {Collapse, Spin, Typography} from 'antd';
import {Template} from "../../components/template";
import {useApi} from "../../api";
import {TemplateEntity} from "../../types/entities";
import {withDelay} from "../../common/utils/withDelay";



export const Templates = () => {
    const templatesApi = useApi('templates');

    const [loading, setLoading] = useState(true);

    const [templates, setTemplates] = useState<TemplateEntity[]>([]);
    const [activePanels, setActivePanels] = useState<string[]>([]);

    useEffect(() => {
        setLoading(true);
        templatesApi.get<TemplateEntity[]>()
            .then(response => {
                if (response) {
                    setTemplates(response.data);
                }
            })
            .finally(() => withDelay(() => setLoading(false), 500))
    }, []);

    const onChangePanel = (key: string | string[]) => {
        if (typeof key === "string") {
            setActivePanels([key])
        } else {
            setActivePanels(key);
        }
    };

    if (loading) {
        return (
            <Spin />
        )
    }

    return (
        <div className="ls-templates">
            <Typography.Title level={2} children={"Templates"} className="ls-page-title" />
            <Typography.Text children={"https://core.telegram.org/api/entities"} />
            <Collapse activeKey={activePanels} expandIconPosition="right" onChange={onChangePanel}>
                {
                    templates.map(t => {
                        return (
                            <Collapse.Panel key={t.id} header={t.name}>
                                <Template {...t} />
                            </Collapse.Panel>
                        )
                    })
                }
            </Collapse>
        </div>
    )
};