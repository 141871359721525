import {Card, Spin, Statistic, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {useApi} from "../../api";
import {withDelay} from "../../common/utils/withDelay";

export const Dashboard = () => {
    const serverAPI = useApi('server');

    const [loading, setLoading] = useState(true);

    const [uptimeInSeconds, setUptimeInSeconds] = useState<number | undefined>(undefined)
    const [formattedUptime, setFormattedUptime] = useState<string>();

    useEffect(() => {
        setLoading(true);
        Promise.all([
            serverAPI.get<number>("uptime")
        ])
            .then(([uptime]) => {
                if (uptime) {
                    const _uptimeInSeconds = Math.floor(uptime.data);
                    setUptimeInSeconds(_uptimeInSeconds);
                    setFormattedUptime(formatUptime(_uptimeInSeconds));
                }
            })
            .finally(() => withDelay(() => setLoading(false), 500))
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            if (uptimeInSeconds) {
                const next = uptimeInSeconds + 1;
                setUptimeInSeconds(next);
                setFormattedUptime(formatUptime(next));
            }
        }, 1000)

        return () => clearInterval(interval);
    }, [uptimeInSeconds])

    const formatUptime = (uptime: number) => {
        if (uptime) {
            const hours = Math.floor(uptime / (3600));
            const minutes = Math.floor((uptime % (3600)) / 60);
            const seconds = Math.floor(uptime % 60);
            const pad = (time: number) => {
                return (time < 10 ? '0' : '') + time;
            }

            return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
        }

        return '';
    }

    if (loading) {
        return (
            <Spin/>
        )
    }

    return (
        <div className="ls-dashboard">
            <Typography.Title level={2} children={"Dashboard"} className="ls-page-title"/>
            {
                uptimeInSeconds != null && <Card><Statistic title={"Server uptime"} value={formattedUptime}/></Card>
            }
        </div>
    )
};