import React, {useEffect, useState} from "react";
import {Typography, Layout, Menu, Tag, Tabs} from "antd";
import {RouteComponentProps, useLocation, useParams} from "react-router-dom";
import {useApi, useWebsocket} from "../../api";
import {ChatEntity} from "../../types/entities";
import {withDelay} from "../../common/utils/withDelay";
import "./chats.scss";
import {ChatV2} from "../../components/chatv2";

type ChatsProps = {} & RouteComponentProps;

const getParamsValue = (params: any, propName: string) => {
    return params[propName];
}

export const Chats = ({history}: ChatsProps) => {
    const params = useParams();

    const chatsApi = useApi('chats');
    const messagesWS = useWebsocket('messages');

    const [chatId, setChatId] = useState<string>(() => getParamsValue(params, 'chatId'));

    const [chats, setChats] = useState<ChatEntity[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        loadChats();
    }, []);

    useEffect(() => {
        const _chatId = getParamsValue(params, 'chatId');
        setChatId(_chatId);
    }, [params]);

    useEffect(() => {
        const newMessagesCallback = () => {
            loadChats();
        }

        messagesWS?.on('new_messages', newMessagesCallback);

        return () => {
            messagesWS?.off('new_messages', newMessagesCallback)
        }
    }, [messagesWS])


    const loadChats = () => {
        setLoading(true);
        chatsApi.get<ChatEntity[]>()
            .then(result => {
                const newChats = result.data;
                setChats(newChats)

                const redirectToChat = (chatToRedirect: ChatEntity) => {
                    history.replace(`/chats/${chatToRedirect.chat_id}`)
                    console.log('redirecting to', chatToRedirect.chat_id);
                }

                const newChat = chats[0];

                if (!chats || chats.length === 0) {
                    redirectToChat(newChat)
                    return;
                }

                if ((chatId == null || chatId == "") && newChats.length > 0) {
                    redirectToChat(newChat);
                    return;
                }

                const routeCorrect = newChats.some(c => c.chat_id == chatId);
                if (!routeCorrect) {
                    history.replace("/chats")
                    return;
                }
            })
            .finally(() => withDelay(() => setLoading(false), 500))
    }

    const onSelectChat = (chatId: string) => {
        history.replace(`/chats/${chatId}`);
    }

    const chatUserName = (chat: ChatEntity) => [chat.firstName, chat.username, chat.lastName]
        .filter(x => Boolean(x))
        .join(' ');

    const tabTitle = (chat: ChatEntity) => (
        <div className="ls-chat-list-item">
            <div className="ls-chat-list-item-username">
                {chatUserName(chat)}
            </div>
            <div className="ls-chat-list-item-lastmessage">
                {chat.text}
            </div>
        </div>
    )
    return (
        <div className="ls-chats">
            <Typography.Title level={2} children={"Chats"} className="ls-page-title"/>
            <Tabs tabPosition="left" className="ls-chat-tabs" activeKey={chatId} onChange={onSelectChat}>
                {chats.map(c => (
                    <Tabs.TabPane tab={tabTitle(c)} key={c.chat_id}>
                        <ChatV2 {...c} />
                    </Tabs.TabPane>
                ))}
            </Tabs>
        </div>
    )


    // return (
    //     <div className="ls-chats">
    //         <Typography.Title level={2} children={"Chats"} className="ls-page-title"/>
    //         <Layout className="ls-chats-layout">
    //             <Sider width={260} theme="light" className="ls-chats-layout-sider">
    //                 <Menu mode="inline" className="ls-chat-items" selectedKeys={[chatId]}>
    //                     {chats.map((c, i) => {
    //                         return (
    //                             <Menu.Item key={c.chat_id} className="ls-chat-item" onClick={() => onSelectChat(c.chat_id)}>
    //                                 <div className="ls-chat-item-username">
    //                                     {getChatUserName(c)}
    //                                 </div>
    //                                 <div className="ls-chat-item-lastmessage">
    //                                     {c.text}
    //                                 </div>
    //                             </Menu.Item>
    //                         )
    //                     })}
    //                 </Menu>
    //             </Sider>
    //             <Content className="ls-chats-layout-content">
    //                 {
    //                     chatId == null
    //                         ? <Tag className="ls-chat-no-selected" children="Select chat" />
    //                         : <Chat chatId={chatId} />
    //                 }
    //             </Content>
    //         </Layout>
    //     </div>
    // )
};