import React from "react";
import {useHistory} from "react-router-dom";
import "./login.scss";
import {useApi} from "../../api";
import {Button, Card, Form, Input } from "antd";

export const Login = () => {
    const history = useHistory();
    const authAPI = useApi('authentication');

    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
    };
    const tailLayout = {
        wrapperCol: { span: 24 },
    };

    const onFinish = (values: any) => {
        authAPI.post("login", values)
            .then(() => {
                authAPI.get("authenticated")
                    .then(() => {
                        history.push("/")
                    })
            })
    };

    return (
        <div className="ls-login">
            <Card style={{ width: 800 }}>
                <Form
                    {...layout}
                    name="basic"
                    onFinish={onFinish}
                >
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password />
                    </Form.Item>


                    <Form.Item {...tailLayout}>
                        <Button type="primary" block htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    )
}