import {useEffect} from "react";
import {useLocation, useHistory} from "react-router-dom";
import axios, {AxiosResponse} from "axios";
import io, {Manager} from "socket.io-client";

const origin = window.location.origin;
const wsOrigin = origin
    .replace('http', 'ws')
    .replace('https', 'ws')

export const useApi = (basePath: string) => {
    const history = useHistory();

    const axiosInstance = axios.create({
        baseURL: `${origin}/api`,
        withCredentials: true,
    });

    useEffect(() => {
        axiosInstance.interceptors.response.use(
            response => {
                return response;
            },
            error => {
                const {response} = error;
                if (response != null && response.status === 403) {
                    history.push('/login')
                }
            }
        );
    }, [axiosInstance])

    function get<T extends any>(url: string = ""): Promise<AxiosResponse<T>> {
        return axiosInstance.get<T>(`${basePath}/${url}`);
    }

    function post<T extends any>(url: string = "", data: any = {}): Promise<AxiosResponse<T>> {
        return axiosInstance.post<T>(`${basePath}/${url}`, data);
    }

    return {
        get, post
    }
}

export const useWebsocket = (path: string) => {
    const connectionPath = `${wsOrigin}/${path}`;
    return io.connect(connectionPath, {
        transports: ["websocket"]
    });
}